import Link from 'next/link';
import React from 'react';
import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getIsUserAuthorized } from '@/store/features/userSlice';
import { showLogin, showLoginModal } from '@/store/features/loginSlice';
import { AppDispatch } from '@/store';
import { hideMenu } from '@/store/features/mobileMenuSlice';
import WsStaticImage from '@/components/common/WsStaticImage';

interface Props {
  // Define the props for your component here
}
const Footer: React.FC<Props> = (
  {
    /* Destructure props here */
  }
) => {
  const isUserAuthorized = useSelector(getIsUserAuthorized);
  const dispatch = useDispatch<AppDispatch>();
  const handleAccountRedirection = (e: any) => {
    if (!isUserAuthorized) {
      e.preventDefault();
      dispatch(hideMenu());
      dispatch(showLoginModal());
      dispatch(showLogin());
    }
  };
  return (
    <div className={styles['menu-footer']}>
      <ul>
        <li>
          <Link href={'/track-order-by-email'} className={styles['outer-menu']}>
            <WsStaticImage
              src={'/images/header/Track-order.svg'}
              alt="Track-order"
              width={22}
              height={22}
            />
            <div>
              <span className={styles['menu-link']}> Track Order</span>
            </div>
          </Link>
        </li>
        <li>
          <Link
            href={'/offline-furniture-store'}
            className={styles['outer-menu']}
          >
            <WsStaticImage
              src={'/images/header/Stores-img.svg'}
              alt="Stores-img"
              width={22}
              height={22}
            />
            <div>
              <span className={styles['menu-link']}> Stores</span>
            </div>
          </Link>
        </li>
        <li>
          <Link href={'/furniture-franchise'} className={styles['outer-menu']}>
            <WsStaticImage
              src={'/images/header/Franchise.svg'}
              alt="Franchise"
              width={22}
              height={22}
            />
            <div>
              <span className={styles['menu-link']}> Furniture Franchise</span>
            </div>
          </Link>
        </li>
        <li>
          <Link href={'/design-services/'} className={styles['outer-menu']}>
            <WsStaticImage
              src={'/images/header/Design.svg'}
              alt="Design"
              width={22}
              height={22}
            />
            <div>
              <span className={styles['menu-link']}> Design Services </span>
            </div>
          </Link>
        </li>
        <li>
          <Link href={'/blog/'} className={styles['outer-menu']}>
            <WsStaticImage
              src={'/images/header/blogs.svg'}
              alt="blogs"
              width={22}
              height={22}
            />
            <div>
              <span className={styles['menu-link']}> Blogs </span>
            </div>
          </Link>
        </li>
        <li>
          <Link href={'/about-us'} className={styles['outer-menu']}>
            <WsStaticImage
              src={'/images/header/abouts.svg'}
              alt="abouts"
              width={22}
              height={22}
            />
            <div>
              <span className={styles['menu-link']}> About Us</span>
            </div>
          </Link>
        </li>
        <li>
          <Link href={'/help-center'} className={styles['outer-menu']}>
            <WsStaticImage
              src={'/images/header/support.svg'}
              alt="support"
              width={22}
              height={22}
            />
            <div>
              <span className={styles['menu-link']}> Support</span>
            </div>
          </Link>
        </li>
        <li>
          <Link
            href={'/account'}
            className={styles['outer-menu']}
            onClick={(e: any) => handleAccountRedirection(e)}
          >
            <WsStaticImage
              src={'/images/header/account.svg'}
              alt="account"
              width={22}
              height={22}
            />
            <div>
              <span className={styles['menu-link']}> My Account </span>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
